import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import SectionHeader from '@src/components/common/section/header';
import KnowhowList from './list';

export default function Knowhow() {
	return (
		<Container>
			<SectionHeader
				title="탑기밀의 이유있는 베스트셀러"
				desc="실무 경험부터 포트폴리오 제작, 면접 준비까지. 현직자의 노하우를 지금 바로 읽어보세요."
				subDesc=""
			/>
			<KnowhowList />
		</Container>
	);
}
const Container = styled.section`
	${cssMixin.container};
	display: flex;
	flex-direction: column;
	margin-top: 0.8rem;
	margin-bottom: 0.71rem;
	${({ theme }) => theme.breakpoint.labtop`
		margin-bottom: 0.62rem;
	`}
	${({ theme }) => theme.breakpoint.tablet`
		margin-bottom: 0.55rem;
	`}
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom: 0.56rem;
		margin-top:0.2rem;
	`}
`;

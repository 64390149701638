import React from 'react';
import styled from 'styled-components';
import { P } from '../atoms';

import PDFWrapperIcon from '../icons/common/pdf-wrapper';

export type NameWrapperProps = {
	width?: string;
	height?: string;
	text: string;
};

export default function PDFWrapper({ width, height, text }: NameWrapperProps) {
	return (
		<Background style={{ width, height }}>
			<PDFWrapperIcon
				width="100%"
				height="100%"
				style={{ position: 'absolute', top: 0, left: 0 }}
				fill="white"
			/>
			<P color="white" level={2}>
				{text}
			</P>
		</Background>
	);
}

const Background = styled.div`
	width: 1.13rem;
	height: 0.44rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;

import React from 'react';

import MainLayout from '@src/components/layouts/main';
import Main from '@src/components/pages/home/main';

const meta = {
	title: '탑기밀',
	siteDescription:
		'탑기밀(TOPGIMIL)은 정보의 가치를 아는 사람들의 시행착오를 줄여주고 꿈을 더 빠르게 이룰 수 있도록 돕는 서비스입니다.',
	pageUrl: 'https://topgimil.net',
};

export default function Home() {
	return (
		<MainLayout {...meta}>
			<Main />
		</MainLayout>
	);
}

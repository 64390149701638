import React from 'react';
import styled from 'styled-components';

import { Color, P } from '@src/components/atoms';
import Price from '@src/components/common/card/price';
import Review from '@src/components/common/card/review';
import cssMixin from '@src/style';

import { ProductType } from '@src/types/product';

export type InfoProps = Pick<
	ProductType,
	| 'category'
	| 'title'
	| 'shortDescription'
	| 'originalPrice'
	| 'salePrice'
	| 'sellMethod'
	| 'nickname'
	| 'review'
	| 'author'
	| 'starRate'
> & { className?: string };

export default function Info({
	className,
	category,
	title,
	shortDescription,
	originalPrice,
	salePrice,
	sellMethod,
	nickname,
	review,
	author,
	starRate,
}: InfoProps) {
	return (
		<Wrapper className={className}>
			<P
				level={2}
				mb={4}
				width="100%"
				fontWeight={500}
				ellipsis
				fontSizes={[18, 15]}
				numOfLines={2}
				minNumOfLines={2}
				color={'#3e4042'}
				letterSpacing={'-0.2px'}
				lineHeight={1.4}
			>
				{title}
			</P>
			<PriceWrapper>
				<Price {...{ originalPrice, salePrice, nickname }} fontSize={18} />
			</PriceWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	padding-top: 0.16rem;

	${({ theme }) => theme.breakpoint.labtop`
		padding: 0.16rem;
	`}
	${({ theme }) => theme.breakpoint.mobile`
		padding: 0.12rem;
	`}
`;

const PriceWrapper = styled.div`
	margin-top:0.12rem;
`;
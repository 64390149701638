import React from 'react';
import styled from 'styled-components';

import MainCard, { MainCardProps } from '@src/components/common/card/product/main';
import Col from '@src/components/atoms/col';

import { ThemeType } from '@src/types/theme';
import { bestSellerData } from '@src/data/home/best-seller-3';

export default function BestSellerList() {
	return (
		<Wrapper>
			{bestSellerData.map((data, index) => (
				<React.Fragment key={index}>
					<StyledCol labtop={4}>
						<MainCard {...(data as MainCardProps)} />
					</StyledCol>
				</React.Fragment>
			))}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 -12px;
	margin-top: 12px;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.tablet`
		overflow-x:scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	`}
`;

const StyledCol = styled(Col)`
	${({ theme }: { theme: ThemeType }) => theme.breakpoint.tablet`
		width:33%;
		min-width:3.12rem;
	`}
`;

import React, {useState} from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Modal from '@material-ui/core/Modal';

import cssMixin from '@src/style';
import CartIcon from '@src/components/icons/common/cart';
import { Button, FlexRow, P, Color } from '@src/components/atoms';
import { ThemeType } from '@src/types/theme';
import buttonStyle from '@src/style/button';

declare var window: any;

export default function PopupModal() {
	const router = useRouter();

	const routeToNewTab = () => {
		window.open('https://topgimil.net/mypage', '_blank');
	};

	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Wrapper>
				<Div>
					<Button
						onClick={handleClose}
					>
						<Img src={'/images/popup/x.png'}/>
					</Button>
				</Div>

				<Button2>
					<P pxSize={16} color='black' lineHeight={1} fontWeight={700}>
						탑기밀 안내말씀
					</P>
				</Button2>
				
				<Div2>
					<P1>
						안녕하세요, {"'"}탑기밀{"'"}을{"\n"}
						사랑해주신 고객 여러분께 {"\n"}
						진심으로 감사드립니다. {"\n"}
						{"\n"}
						그동안 여러분의 뜨거운 성원에 {"\n"}
						힘입어 서비스를 운영해왔지만, {"\n"}
						재무적인 어려움으로 인해 {"\n"}
						아쉽게도 10월까지만 판매를 {"\n"}
						진행하고, 이후 서비스를 {"\n"}
						종료하게 되었습니다. {"\n"}
						고객님들께 불편을 드리게 되어 {"\n"}
						죄송한 마음을 전하며, {"\n"}
						사랑해주셨던 순간들을 {"\n"}
						소중히 간직하겠습니다. {"\n"}
						판매는 올해 10월 말까지 진행되며, {"\n"}
						이후에도 12월까지는 {"\n"}
						고객 지원을 위해 사이트를 {"\n"}
						유지할 예정입니다. {"\n"}
						문의 사항이나 필요한 지원이 {"\n"}
						있으시면 언제든지 {"\n"}
						pieceofmood@naver.com으로 {"\n"}
						연락 주시기 바랍니다. {"\n"}
						{"\n"}
						다시 한 번 감사드리며, 더 나은 모습으로 {"\n"}
						다시 찾아뵐 수 있기를 바랍니다. {"\n"}
						감사합니다. {"\n"}
						{"\n"}
						{"'"}탑기밀{"'"} 드림{"\n"}
					</P1>
				</Div2>


			</Wrapper>
		</Modal>
	);
}

const Wrapper = styled.div`
	max-width: 7.76rem;
	width: 479px;
	height: 988px;
	background-color: white;
	border-radius: 28px;
	padding: 0.36rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	${cssMixin.flexCol}
	align-items:center;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.mobile`
		width: 300px;
		height: 400px;
	`}
`;


const Div = styled.div`
	position: absolute;
    right: 16px;
	top: 16px;
`;

const Div2 = styled.div`
	text-align :center;
	margin-bottom: 20px;
	border: solid;
	border-width: thin;
	padding: 20px;
`;

const Img = styled.img`
	width: 37px;
	height: 37px;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.mobile`
		width: 33px;
		height: 33px;
	`};
`;

const Button2 = styled.button`
	${buttonStyle.Default};

	width: 240px;
	height: 48.35px;
	background-color: #FFD02B;
	margin-top: 18px;
	margin-bottom: 26px;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.mobile`
		width: 249px;
		height: 43px;
	`};
`;

const P1 = styled.p`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 6px;
	white-space: pre-line;
	line-height: 27px;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.mobile`
		font-size: 19px;
	`};


`;

import React from 'react';
import styled, { keyframes } from 'styled-components';

import Info, { InfoProps } from './info';
import LinkWrapper from '@src/components/molecules/link';
import { Color, ResImg, TouchBox, Thumbnail } from '@src/components/atoms';

import { ThemeType } from '@src/types/theme';
import { ProductType } from '@src/types/product';
import MainCardOverlay from './overlay';

import Review from '@src/components/common/card/review';

export type MainCardProps = Pick<ProductType, 'thumbnailUrl' | 'title' | 'productId'> & InfoProps;

export default function MainCard(props: MainCardProps) {
	const { thumbnailUrl, title, productId, shortDescription, category, salePrice } = props;

	return (
		<MainWrapper>
			<LinkWrapper href="/product/[id]" as={`/product/${productId}`}>
				<Background>
					<MainCardOverlay {...{ title, shortDescription, category }} />
					<TouchBox>
						<Wrapper>
							<ImageWrapper>
								<Thumbnail
									src={thumbnailUrl}
									alt={title}
									lazyLoad
									setLabel={salePrice === 0 ? true : false}
								/>
							</ImageWrapper>
							<Info className="disappear-info" {...(props as InfoProps)} />
						</Wrapper>
					</TouchBox>
				</Background>
			</LinkWrapper>
			<ReviewWrapper>
				<Review {...(props as InfoProps)} fontSize={14} fontSizes={[14, 13]} />
			</ReviewWrapper>
		</MainWrapper>
	);
}

const Background = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.2rem;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	${({ theme }) => theme.breakpoint.mobile`
		border-radius: 4px;
		border: 1px solid ${Color.BORDER_GERY};
	`}
`;

const ImageWrapper = styled.div`
	border-radius: 0.04rem;
	overflow: hidden;
`;

const ReviewWrapper = styled.div`
	margin-top: 0.24rem;
`;

const MainWrapper = styled.div`
	font-family: 'Spoqa Han Sans Neo';
`;

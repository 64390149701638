import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import HomeCarousel from './carousel';
import HomeCategory from './category';
import BestSeller1 from './best-seller-1';
import BestSeller2 from './best-seller-2';
import BestSeller3 from './best-seller-3';
import Bx from './bx';
import Banner from './banner';
import Expert from './expert';
import Reviews from './reviews';
import cssMixin from '@src/style';

import { P, FlexRow, TouchBox } from '@src/components/atoms';
import LinkWrapper from '@src/components/molecules/link';
import { ThemeType } from '@src/types/theme';
// import KakaoChatButton from '@src/components/molecules/button/KakaoChatButton';

import PopupModal from '@src/components/pages/home/main/modal/popup';

const NewlyUpdated = dynamic(() => import('./newly-updated'));

declare var window: any;

export default function Main() {
	useEffect(() => {
		// 채널톡 버튼 생기게 하기
		window.ChannelIO('showChannelButton');
	});

	return (
		<MainWrapper>
			<PopupModal />
			{/* <KakaoChatButton /> */}
			{/* <HomeCarousel /> */}
			{/* <HomeCategory /> */}
			<BestSeller1 />
			<BestSeller2 />
			<BestSeller3 />
			<Bx />
			{/* <Banner /> */}
			{/* <Expert /> */}
			{/* <Reviews /> */}
			<NewlyUpdated />
			<MoreWrapper>
				<P
					level={1}
					style={{ marginBottom: '0.16rem', marginLeft: 'auto', marginRight: 'auto' }}
					mb={8}
					fontWeight="600"
					color={'#606262'}
					fontSizes={[16, 15]}
				>
					{'혹시 원하는 자료가 없으신가요?'}
				</P>
				<LinkWrapper href="https://docs.google.com/forms/d/1Ued1N7ysISwC-g9FDTwKVfmQ-d3IiwHYPTMtuHUZFoM/edit">
					<ButtonWrapper>
						<TouchBox>
							<P level={1} fontWeight="800" lineHeight={1} color={'#9b9b9b'} fontSizes={[16, 15]}>
								{'노하우 요청하기'}
							</P>
						</TouchBox>
					</ButtonWrapper>
				</LinkWrapper>
			</MoreWrapper>
		</MainWrapper>
	);
}
const MainWrapper = styled.main`
	${cssMixin.defaultMainStyle}
`;

const ButtonWrapper = styled.div`
	${cssMixin.flexRow}
	justify-content: center;
	align-items: flex-end;
	position: relative;

	width: fit-content;
	padding: 0.2rem 0.6rem 0.2rem 0.6rem;

	border: 1px solid #c2c2c2;
	box-shadow: 0px 6px 7px rgba(220, 220, 220, 0.2);
	border-radius: 100px;

	cursor: pointer;
	&:hover {
		transition: all 0.3s ease-in-out;
		transform: scale(1.05);
		transform: translateY(-10%);
	}
	${({ theme }) => theme.breakpoint.mobile`
		padding: 0.12rem 0.24rem 0.12rem 0.24rem;
		margin-left: auto;
		margin-right: auto;
	`}
`;

const MoreWrapper = styled.main`
	margin-bottom: 1.6rem;
	margin-top: 0.3rem;
	text-align: center;
	font-family: 'Spoqa Han Sans Neo';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;

	${({ theme }) => theme.breakpoint.mobile`
		margin-top: 1.30rem;
	`}
`;

import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import SectionHeader from '@src/components/common/section/header';
import BestSellerList from './list';

export default function BestSeller2() {
	return (
		<Container>
			<SectionHeader
				title="포트폴리오 신상품 Ⅱ"
				desc="성장하고 싶은 디자이너와 기획자가 주목하는 자료들, 지금 바로 만나보세요."
				subDesc=""
			/>
			<BestSellerList />
		</Container>
	);
}
const Container = styled.section`
	${cssMixin.container};
	display: flex;
	flex-direction: column;
	margin-top: 0.8rem;
	margin-bottom: 0.71rem;
	${({ theme }) => theme.breakpoint.labtop`
		margin-bottom: 0.62rem;
	`}
	${({ theme }) => theme.breakpoint.tablet`
		margin-bottom: 0.55rem;
	`}
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom: 0.56rem;
		margin-top:0.2rem;
	`}
`;

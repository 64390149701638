import React from 'react';

import { IconProps } from '@src/types';

export default function PDFWrapperIcon(props: IconProps) {
	return (
		<svg viewBox="0 0 320.3 124.7" {...props}>
			<path
				d="M319.3 124.7H1c-.5 0-1-.4-1-1V1c0-.5.4-1 1-1H281.2c.2 0 .4.1.5.2.1 0 .1.1.2.1l38.2 42.6c.1.2.2.4.2.6v80.2c0 .6-.4 1-1 1zM2 122.8h316.3V44.6h-37.2c-.5 0-1-.4-1-1V2H2v120.8zm280.1-80.2h35l-35-39v39z"
				fill="#fff"
			/>
		</svg>
	);
}

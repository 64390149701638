import React from 'react';
import styled from 'styled-components';

import { P, Color } from '@src/components/atoms';
import cssMixin from '@src/style';
import { addCommaToNumber } from '@src/lib/util/number-parser';

import { ProductType } from '@src/types/product';

type PriceProp = Pick<ProductType, 'originalPrice' | 'salePrice' | 'nickname'> & {
	style?: React.CSSProperties;
	fontSize?: number;
	fontSizes?: number[];
	withOutSalePercent?: boolean;
	isProduct?: boolean;
};

export default function Price({
	originalPrice,
	salePrice,
	style,
	fontSize,
	fontSizes,
	withOutSalePercent,
	nickname,
	isProduct,
}: PriceProp) {
	const isSale = originalPrice !== salePrice;
	const salePercent = Math.round((1 - salePrice / originalPrice) * 100);
	return (
		<div style={{ paddingTop: isSale ? '0rem' : isProduct ? '0rem' : '0.20rem' }}>
			{isSale && (
				<P
					color={Color.GREY}
					textDecoration="line-through"
					style={{ marginLeft: nickname ? 'auto' : '0' }}
					pxSize={fontSize - 4}
					fontSizes={fontSizes}
				>
					{addCommaToNumber(originalPrice)}원
				</P>
			)}
			{nickname && (
				<Wrapper style={style}>
					<P
						pxSize={fontSize - 4}
						fontSizes={fontSizes}
						color={'#9b9b9b'}
						letterSpacing={'-0.2px'}
						style={{ marginRight: 'auto' }}
					>
						{nickname}
					</P>
					{isSale && (
						<>
							{!withOutSalePercent && (
								<P
									color={'#ef4800'}
									style={{ marginRight: '0.08rem' }}
									pxSize={fontSize}
									fontSizes={fontSizes}
									fontWeight="bold"
								>
									{salePercent}%
								</P>
							)}
						</>
					)}
					<P fontWeight="bold" pxSize={fontSize} fontSizes={fontSizes}>
						{addCommaToNumber(salePrice)}원
					</P>
				</Wrapper>
			)}

			{!nickname && (
				<Wrapper style={style}>
					<P
						fontWeight="bold"
						pxSize={fontSize + 2}
						fontSizes={fontSizes}
						style={{ marginRight: '0.08rem' }}
					>
						{addCommaToNumber(salePrice)}원
					</P>
					{isSale && (
						<>
							{!withOutSalePercent && (
								<P color={'#ef4800'} pxSize={fontSize + 2} fontSizes={fontSizes} fontWeight="bold">
									{salePercent}%
								</P>
							)}
						</>
					)}
				</Wrapper>
			)}
		</div>
	);
}

const Wrapper = styled.div`
	${cssMixin.flexRow}
`;

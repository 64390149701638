import React from 'react';
import styled from 'styled-components';

import { P } from '@src/components/atoms';
import cssMixin from '@src/style';

import StarIcon from '../../icons/star2';
import { ProductType } from '@src/types/product';

type ReviewProp = Pick<ProductType, 'review' | 'author' | 'starRate'> & {
	style?: React.CSSProperties;
	fontSize?: number;
	fontSizes?: number[];
};

export default function Review({
	review,
	author,
	starRate,
	style,
	fontSize,
	fontSizes,
}: ReviewProp) {
	return (
		<div style={{borderRadius:'0.04rem', backgroundColor:'#f7f7f7', height:'1rem', padding:'0.16rem'}}>
			<Wrapper>
				{getStars(starRate)}
				<P 
					fontWeight='500' 
					pxSize={fontSize-2} 
					style={{marginLeft:'auto', color:'#9b9b9b'}}
				>
					{author}
				</P>
			</Wrapper>
			<P 
				fontWeight="500" 
				pxSize={fontSize} 
				fontSizes={fontSizes} 
				style={{marginTop:'0.04rem', color:'#606262'}}
				letterSpacing={'-0.2px'}
				lineHeight={1.4}
			>
				{review}
			</P>
		</div>
	);
}

const Wrapper = styled.div`
	${cssMixin.flexRow}
`;

const getStars = rate =>
	Array.apply(null, Array(5)).map((_v, i) => (
		<StarIcon
			key={'star_' + i}
			width="0.24rem"
			height="0.24rem"
			fill={'#ffd000'}
		/>
	));
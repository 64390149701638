import React from 'react';
import styled from 'styled-components';

import { P, Color, FlexRow } from '@src/components/atoms';

export type SectionHeaderProps = {
	title: string;
	desc?: string;
	subDesc?: string;
};

export default function SectionHeader({ title, desc, subDesc }: SectionHeaderProps) {
	return (
		<Wrapper>
			<Title level={6} fontSizes={[28, 18]} mb={8} style={{color: '#1c1c1c', fontWeight: 800}}>
				{title}
			</Title>
			{desc && (
				<Row>
					<P level={1} fontSizes={[16, 16, 13]} style={{color: '#606262', fontFamily: 'Spoqa Han Sans Neo', letterSpacing: '-0.2px'}}>
						{desc}
					</P>
					{subDesc && (
						<SubDesc level={1} fontSizes={[16, 16, 13]} style={{color: '#3e4042', fontFamily: 'Spoqa Han Sans Neo', letterSpacing: '-0.2px', fontWeight:500}}>
							{subDesc}
						</SubDesc>
					)}
				</Row>
			)}
			{!desc && subDesc && (
				<P level={2} fontSizes={[16, 16, 13]} color={Color.GRAY800}>
					{subDesc}
				</P>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
	height: fit-content;
	margin-bottom: 0.2rem;
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom:0.12rem;
	`}
`;

const SubDesc = styled(P)`
	position: relative;
	margin-left: auto;

	${({ theme }) => theme.breakpoint.mobile`
		display:none;
	`}
`;

const Row = styled(FlexRow)`
`;

const Title = styled(P)`
	margin-bottom: '0.12rem'
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom: '0.08rem'
	`}
`;
export const knowhowData = [
	{
		title: '[UX/서비스기획] 1년 반 경력으로 S그룹 대기업에 이직한 UX 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bx/1.png',
		productId: 121,
		nickname: '여우다람쥐',
		originalPrice: 148000,
		salePrice: 148000,
		review: '이런 사람들이 대기업에 이직하는구나 싶은 포폴이었습니다. 잘 참고해서 이직 성공하려구요! ',
		author: '류** 님',
		starRate: 5,
	},
	{
		title: '[Product/UX/UI] 유명IT대기업 4개에 합격, 재직한 현직자의 원샷원킬 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bx/2.png',
		productId: 124,
		nickname: 'M06B',
		originalPrice: 190000,
		salePrice: 190000,
		review: '리뷰 날짜까지 포트폴리오를 완성하지 못해 양해를 구하고 일정을 변경했을 때도 친절하게 조정해 주셨습니다!',
		author: '여** 님',
		starRate: 5,
	},
	{
		title: '[Product/UX/UI] 9개 유명 스타트업에 합격한 프로덕트 디자이너의 포트폴리오 가이드',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bx/3.png',
		productId: 116,
		nickname: '요모조모',
		originalPrice: 100000,
		salePrice: 100000,
		review: '어떻게 구성하면 좋은지 전반적인 프로세스를 나눠가며 설명해주는 게 특히 좋았습니다.',
		author: '박** 님',
		starRate: 5,
	},
];

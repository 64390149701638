import React from 'react';

import { IconProps } from '@src/types';

export default function StarIcon({
	width,
	height,
	style,
	fill,
	onClick,
}: IconProps) {
	return (
		<svg {...{ width, height, style, onClick }} viewBox="0 0 24 24">
			<g data-name="icon_star rank-01" transform="translate(0)">
				<path
					fill={fill}
					d="M12 15.067l4.947 3.6-1.894-5.814L20 9.333h-6.067l-1.933-6-1.933 6H4l4.947 3.52-1.894 5.814 4.947-3.6z"
				/>
			</g>
		</svg>
	);
}

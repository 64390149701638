export const bestSellerData = [
	{
		title: '2년차 때 미들급 대기업, 유니콘 기업 모두  포폴 합격한 Product Designer 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller3/1.png',
		productId: 134,
		nickname: 'Purplejuice',
		originalPrice: 150000,
		salePrice: 119000,
		review: '지금 주니어로 이직 준비하고 있는데 도움 많이 됐어요! 감사합니다!',
		author: 'Gr** 님',
		starRate: 5,
	},
	{
		title: '[Product/UX/UI] 9개 유명 스타트업에 합격한 프로덕트 디자이너의 포트폴리오 가이드',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller3/2.png',
		productId: 116,
		nickname: '요모조모',
		originalPrice: 100000,
		salePrice: 100000,
		review: '어떻게 구성하면 좋은지 전반적인 프로세스를 나눠가며 설명해주는 게 특히 좋았습니다.',
		author: '박** 님',
		starRate: 5,
	},
	{
		title: '중소기업에서 네카라쿠배까지',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller3/3.png',
		productId: 126,
		nickname: '여우다람쥐',
		originalPrice: 39000,
		salePrice: 39000,
		review: '이직의 과정과 포트폴리오 내용, 구성방법까지 잘 정리되어 있습니다.',
		author: '은* 님',
		starRate: 5,
	},
];

export const bestSellerData = [
	{
		title: '유학없이 해외 글로벌 기업 합격한 UX/UI 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller2/1.png',
		productId: 139,
		nickname: '써니월드',
		originalPrice: 300000,
		salePrice: 260000,
		review: '막연하게 해외 취업하고 싶었는데  덕분에 어떻게 준비하면 좋을지 감 잡을 수 있었어요! 가격이 싸지 않은데 구매한만큼 잘준비해보려 합니다!',
		author: '백** 님',
		starRate: 5,
	},
	{
		title: '먼저 면접제의가 오는 <서비스기획> 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller2/2.png',
		productId: 132,
		nickname: '맥킨토시',
		originalPrice: 80000,
		salePrice: 72000,
		review: '기획자 포트폴리오 어떻게 만들어야할지 고민 많았는데 참고하기 좋았어요!',
		author: '글로** 님',
		starRate: 5,
	},
	{
		title: '[프로덕트 디자인] 스타트업에서 1년만에 ‘카카오 모빌리티, 쿠팡, 하이브랩, 하나투어’ 모두 합격한 디자이너의 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller2/3.png',
		productId: 135,
		nickname: 'BT비티',
		originalPrice: 99000,
		salePrice: 65000,
		review: '저도 현재 스타트업에 재직하며 이직을 준비하고 있는데, 스타트업 서비스는 잘모르시는 경우가 많아 어떻게 포트폴리오를 구성해야할지 고민이 정말 많아 구매했습니다.',
		author: '김** 님',
		starRate: 5,
	},
];

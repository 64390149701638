export const bestSellerData = [
	{
		title: '문제해결력에 집중한 프로덕트 디자이너의 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller1/1.png',
		productId: 141,
		nickname: '에릭',
		originalPrice: 300000,
		salePrice: 260000,
		review: '디자인도 잘 하시고 전체적으로 문제 해결력에 맞춰서 논리적으로 구성된 점이 맘에 듭니다.',
		author: '지* 님',
		starRate: 5,
	},
	{
		title: '유명 기업 다수 합격한 마케팅•브랜드 디자인 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller1/2.png',
		productId: 136,
		nickname: '그릭지',
		originalPrice: 125000,
		salePrice: 100000,
		review: '마케터로 취업하고 싶은 대학생입니다! 이전에 인턴하면서 일했던 부분을 어떻게 포트폴리오로 정리할까 고민이 많았는데 참고하기 좋아서 방향을 잡을 수 있었어요! 감사합니다!',
		author: '박** 님',
		starRate: 5,
	},
	{
		title: '스타트업 프로젝트로 대기업 합격한 PM/PO의 포트폴리오',
		shortDescription: ``,
		category: { name: '취업기밀' },
		sellMethod: 1,
		thumbnailUrl: '/images/bestseller1/3.png',
		productId: 140,
		nickname: '마멋',
		originalPrice: 240000,
		salePrice: 189000,
		review: '가이드라인에 꿀팁이 정말 많아 도움 많이 됐어요! 좋은 자료 감사합니다!',
		author: '강** 님',
		starRate: 5,
	},
];

import React from 'react';
import styled from 'styled-components';

import { Line, P } from '@src/components/atoms';
import cssMixin from '@src/style';
import PDFWrapper from '@src/components/common/pdf-wrapper';

export default function MainCardOverlay({ title, shortDescription, category }) {
	return (
		<Overlay className="overlay">
			<ShorInfo className="info">
				<CategoryWrapper>
					<Line color="white" level={1} perLength="2.5%" />
					<PDFWrapper text={category.name} width="30%" />
					<Line color="white" level={1} perLength="62%" />
				</CategoryWrapper>
				<InfoDetail>
					<P color="white" fontWeight="bold" level={2} mb={14}>
						{title}
					</P>
					<P color="white" lineHeight={1.5}>
						{shortDescription}
					</P>
				</InfoDetail>
			</ShorInfo>
		</Overlay>
	);
}

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	${({ theme }) => theme.breakpoint.tablet`
		display:none;
	`}
`;

const ShorInfo = styled.div`
	${cssMixin.flexCol};
	width: 100%;
	height: 100%;
	justify-content: flex-end;
	padding-bottom: 0.3rem;
`;

const CategoryWrapper = styled.div`
	width: 100%;
	height: fit-content;
	${cssMixin.flexRow};
	justify-content: space-between;
	margin-bottom: 0.2rem;
`;

const InfoDetail = styled.div`
	${cssMixin.flexCol};
	padding-left: 0.2rem;
	padding-right: 0.2rem;
`;
